import React from 'react';
import { ITableCell } from 'interfaces/ITableCell';
import styles from '../EditTable/EditTable.module.scss';
import {
  ILabelCell,
  ICellFormatter,
  ChangeFocusOrder,
} from '../EditTable/interfaces';
import { REGEX_THOUSAND_SEP_WITH_DECIMALS } from 'constants/regex';
import { convertToNumber } from 'utils/parsers/convertToNumber';
import { currencyInputFormatter } from 'utils/formatters/currency';
import Dropdown from 'components/Dropdown';
import { LeaseRentType } from 'constants/leases';
import { getTranslatedRentType } from 'utils/leases';
import DateInput from 'components/Inputs/DateInput';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { I18N_HISTORICAL_PERFORMANCE_LABEL_PATH } from 'components/HistoricalPerformance/constants';
import { PropertyTypes } from 'constants/propertyTypes';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';

export enum AvailabilityVacancyFields {
  availableSfDirect = 'availableSfDirect',
  vacantSfDirect = 'vacantSfDirect',
  availableSfSublet = 'availableSfSublet',
  vacantSfSublet = 'vacantSfSublet',
  availableSfShell = 'availableSfShell',
  vacantSfShell = 'vacantSfShell',
  availableKwTurnkey = 'availableKwTurnkey',
  vacantKwTurnkey = 'vacantKwTurnkey',
  baseRent = 'baseRent',
  rentType = 'rentType',
  annualOrMonthly = 'timeMeasurement',
  notes = 'notes',
}

enum RENT_TIMEMEASUREMENT {
  monthly = 'MONTHLY',
  annual = 'ANNUAL',
}

const getVacantAvailableHeaders = (propertyType: PropertyTypes | undefined) => {
  if (propertyType === PropertyTypes.dataCenter) {
    return [
      {
        header: translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.shellAvailable`,
        ),
        accessor: AvailabilityVacancyFields.availableSfShell,
        isAlignedOnRight: true,
      },
      {
        header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.shellVacant`),
        accessor: AvailabilityVacancyFields.vacantSfShell,
        isAlignedOnRight: true,
      },
      {
        header: translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.turnkeyAvailable`,
        ),
        accessor: AvailabilityVacancyFields.availableKwTurnkey,
        isAlignedOnRight: true,
      },
      {
        header: translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.turnkeyVacant`,
        ),
        accessor: AvailabilityVacancyFields.vacantKwTurnkey,
        isAlignedOnRight: true,
      },
    ];
  }
  return [
    {
      header: translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.directAvailable`,
      ),
      accessor: AvailabilityVacancyFields.availableSfDirect,
      isAlignedOnRight: true,
    },
    {
      header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.directVacant`),
      accessor: AvailabilityVacancyFields.vacantSfDirect,
      isAlignedOnRight: true,
    },
    {
      header: translateText(
        `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.subleaseAvailable`,
      ),
      accessor: AvailabilityVacancyFields.availableSfSublet,
      isAlignedOnRight: true,
    },
    {
      header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.subleaseVacant`),
      accessor: AvailabilityVacancyFields.vacantSfSublet,
      isAlignedOnRight: true,
    },
  ];
};

export const availabilityVacancyHeader = (
  propertyType: PropertyTypes | undefined,
) => [
  {
    header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.year`),
    accessor: 'date',
    Cell: ({ cell, updateCellValue, row, column }: ITableCell) => {
      return (
        <DateInput
          id="vacancy-availability-date"
          wrapperClassName={styles['cell-bold']}
          isDisabled={!cell.row.original.isPlaceholder}
          onBlur={(value: string) => {
            updateCellValue({
              rowIndex: row.id,
              columnId: column.id,
              value,
            });
          }}
          value={cell.value}
        />
      );
    },
    isAlignedOnLeft: true,
  },
  ...getVacantAvailableHeaders(propertyType),
  {
    header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.rent`),
    accessor: AvailabilityVacancyFields.baseRent,
    isAlignedOnRight: true,
  },
  {
    header: translateText(`${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.rentType`),
    accessor: AvailabilityVacancyFields.rentType,
    isAlignedOnRight: true,
    Cell: (tableCell: ITableCell) => {
      const fsRentType = getTranslatedRentType(LeaseRentType.FS);
      const nnnRentType = getTranslatedRentType(LeaseRentType.NNN);
      return (
        <Dropdown
          selectedId={tableCell.cell.value}
          editOnFocus
          onChange={v => {
            tableCell.updateCellValue({
              columnId: tableCell.column.id,
              rowIndex: parseInt(tableCell.row.id, 10),
              value: v,
            });
          }}
          defaultOpen
          options={[
            { id: 'FS', label: fsRentType, value: fsRentType },
            { id: 'NNN', label: nnnRentType, value: nnnRentType },
            { id: 'MG', label: LeaseRentType.MG, value: LeaseRentType.MG },
            { id: 'IG', label: LeaseRentType.IG, value: LeaseRentType.IG },
            { id: 'NN', label: LeaseRentType.NN, value: LeaseRentType.NN },
            { id: 'N', label: LeaseRentType.N, value: LeaseRentType.N },
          ]}
        />
      );
    },
  },
  {
    header: translateText(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.annualMonthly`,
    ),
    accessor: AvailabilityVacancyFields.annualOrMonthly,
    isAlignedOnRight: true,
    Cell: (tableCell: ITableCell) => {
      return (
        <Dropdown
          selectedId={tableCell.cell.value}
          editOnFocus
          onChange={v => {
            tableCell.updateCellValue({
              columnId: tableCell.column.id,
              rowIndex: parseInt(tableCell.row.id, 10),
              value: v,
            });
          }}
          defaultOpen
          options={[
            {
              id: RENT_TIMEMEASUREMENT.annual,
              label: RENT_TIMEMEASUREMENT.annual,
              value: RENT_TIMEMEASUREMENT.annual,
            },
            {
              id: RENT_TIMEMEASUREMENT.monthly,
              label: RENT_TIMEMEASUREMENT.monthly,
              value: RENT_TIMEMEASUREMENT.monthly,
            },
          ]}
        />
      );
    },
  },
  {
    header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.note`),
    accessor: AvailabilityVacancyFields.notes,
    isAlignedOnLeft: true,
  },
];

export const getAvailabilityVacancyCellsLabels = ({
  unitOfMeasurement,
  currencySymbol,
  propertyType,
}: {
  unitOfMeasurement?: string;
  currencySymbol?: string;
  propertyType: PropertyTypes | undefined;
}): ILabelCell[] => {
  let vacantAvailable = [];
  if (propertyType === PropertyTypes.dataCenter) {
    vacantAvailable = [
      {
        columnId: AvailabilityVacancyFields.availableSfShell,
        labelRight: unitOfMeasurement,
      },
      {
        columnId: AvailabilityVacancyFields.vacantSfShell,
        labelRight: unitOfMeasurement,
      },
      {
        columnId: AvailabilityVacancyFields.availableKwTurnkey,
        labelRight: UnitOfMeasurement.kw,
      },
      {
        columnId: AvailabilityVacancyFields.vacantKwTurnkey,
        labelRight: UnitOfMeasurement.kw,
      },
    ];
  } else {
    vacantAvailable = [
      {
        columnId: AvailabilityVacancyFields.availableSfDirect,
        labelRight: unitOfMeasurement,
      },
      {
        columnId: AvailabilityVacancyFields.vacantSfDirect,
        labelRight: unitOfMeasurement,
      },
      {
        columnId: AvailabilityVacancyFields.availableSfSublet,
        labelRight: unitOfMeasurement,
      },
      {
        columnId: AvailabilityVacancyFields.vacantSfSublet,
        labelRight: unitOfMeasurement,
      },
    ];
  }
  return [
    ...vacantAvailable,
    {
      columnId: AvailabilityVacancyFields.baseRent,
      labelLeft: currencySymbol,
    },
  ];
};

const validateCellMaxValue = (
  maxCellValue?: number,
  cellValue?: string | number | null,
) => {
  const numVal = convertToNumber(cellValue);

  if (!maxCellValue || !numVal) return true;

  return numVal <= maxCellValue;
};

const availabilityNumberFormatter = (
  value: string | number,
  isFocused?: boolean,
) => currencyInputFormatter(`${value}`, true, isFocused);

export const getAvailabilityVacancyCellsFormatters = (
  maxSfCellValue?: number,
  maxKwCellValue?: number,
): ICellFormatter[] => [
  {
    columnId: AvailabilityVacancyFields.availableSfDirect,
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    formatter: availabilityNumberFormatter,
    isAlignedOnRight: true,
    validator: (cellValue?: string | number | null) =>
      validateCellMaxValue(maxSfCellValue, cellValue),
  },
  {
    columnId: AvailabilityVacancyFields.vacantSfDirect,
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    formatter: availabilityNumberFormatter,
    isAlignedOnRight: true,
    validator: (cellValue?: string | number | null) =>
      validateCellMaxValue(maxSfCellValue, cellValue),
  },
  {
    columnId: AvailabilityVacancyFields.availableSfSublet,
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    formatter: availabilityNumberFormatter,
    isAlignedOnRight: true,
    validator: (cellValue?: string | number | null) =>
      validateCellMaxValue(maxSfCellValue, cellValue),
  },
  {
    columnId: AvailabilityVacancyFields.vacantSfSublet,
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    formatter: availabilityNumberFormatter,
    isAlignedOnRight: true,
    validator: (cellValue?: string | number | null) =>
      validateCellMaxValue(maxSfCellValue, cellValue),
  },
  {
    columnId: AvailabilityVacancyFields.availableSfShell,
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    formatter: availabilityNumberFormatter,
    isAlignedOnRight: true,
    validator: (cellValue?: string | number | null) =>
      validateCellMaxValue(maxSfCellValue, cellValue),
  },
  {
    columnId: AvailabilityVacancyFields.vacantSfShell,
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    formatter: availabilityNumberFormatter,
    isAlignedOnRight: true,
    validator: (cellValue?: string | number | null) =>
      validateCellMaxValue(maxSfCellValue, cellValue),
  },
  {
    columnId: AvailabilityVacancyFields.availableKwTurnkey,
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    formatter: availabilityNumberFormatter,
    isAlignedOnRight: true,
    validator: (cellValue?: string | number | null) =>
      validateCellMaxValue(maxKwCellValue, cellValue),
  },
  {
    columnId: AvailabilityVacancyFields.vacantKwTurnkey,
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    formatter: availabilityNumberFormatter,
    isAlignedOnRight: true,
    validator: (cellValue?: string | number | null) =>
      validateCellMaxValue(maxKwCellValue, cellValue),
  },
  {
    columnId: AvailabilityVacancyFields.baseRent,
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    formatter: availabilityNumberFormatter,
    isAlignedOnRight: true,
  },
];

export const availabilityCellsFocusOrder: ChangeFocusOrder = {
  [AvailabilityVacancyFields.availableSfDirect]:
    AvailabilityVacancyFields.vacantSfDirect,
  [AvailabilityVacancyFields.vacantSfDirect]:
    AvailabilityVacancyFields.availableSfSublet,
  [AvailabilityVacancyFields.availableSfSublet]:
    AvailabilityVacancyFields.vacantSfSublet,
  [AvailabilityVacancyFields.vacantSfSublet]:
    AvailabilityVacancyFields.baseRent,
  [AvailabilityVacancyFields.availableSfShell]:
    AvailabilityVacancyFields.vacantSfShell,
  [AvailabilityVacancyFields.vacantSfShell]:
    AvailabilityVacancyFields.availableKwTurnkey,
  [AvailabilityVacancyFields.availableKwTurnkey]:
    AvailabilityVacancyFields.vacantKwTurnkey,
  [AvailabilityVacancyFields.vacantKwTurnkey]:
    AvailabilityVacancyFields.baseRent,
  [AvailabilityVacancyFields.baseRent]: AvailabilityVacancyFields.rentType,
  [AvailabilityVacancyFields.rentType]: AvailabilityVacancyFields.notes,
  [AvailabilityVacancyFields.notes]:
    AvailabilityVacancyFields.availableSfDirect,
};
