import { gql } from '@apollo/client';
import { LEASE_INFO_FRAGMENT } from 'graphql/leases';
import { PRICE_QUOTE_INFO_FRAGMENT } from 'graphql/propertyPriceQuote/fragments';
import { SALE_BASIC_DATA_FRAGMENT } from 'graphql/sales/fragments';
import { TIM_FRAGMENT } from 'graphql/tims/fragments';

export const GET_ACTIVITIES_QUERY = gql`
  query getActivities($search: ActivitySearchInput) {
    activities(search: $search) {
      totalPages
      currentPage
      results {
        id
        activityDate
        user {
          id
          firstName
          lastName
          email
        }
        userEdited {
          id
          firstName
          lastName
          email
        }
        property {
          id
          name
          country
          state
          primaryAddress
          secondaryAddress
          tertiaryAddress
          quaternaryAddress
          numberOfAlternativeAddresses
          displayAddress
          measurementSystem
          currencyCode
          buildingSize
          buildingSizeMt
          propertyCountry {
            id
            name
            code
            flagURL
            distanceMeasurement
            areaMeasurement
            currencyCode
          }
          country
          latitude
          longitude
          latitudeDisplay
          longitudeDisplay
          state
          status {
            id
            name
          }
          propertyClass {
            id
            name
          }
          market {
            id
            name
          }
          submarket {
            id
            name
          }
          micromarket {
            id
            name
          }
          propertyType {
            id
            name
          }
          coverMediaUrl
          coverMediaType
        }
        scoop {
          id
          documentCount
          text
          userId
          url
          scoopMediaUrl
          scoopMediaId
          user {
            id
            firstName
            lastName
            email
          }
          lastEditedUser {
            id
            firstName
            lastName
            email
          }
          lastEditedUserId
          createdAt
          updatedAt
          propertyId
          totalScoopComments
          propertyTypes {
            id
            name
          }
          scoopCategories {
            id
            name
          }
          companies {
            id
            name
            mergedCompanyId
            mergedCompany {
              id
              name
            }
          }
          properties {
            id
            name
            primaryAddress
            displayAddress
            secondaryAddress
            tertiaryAddress
            quaternaryAddress
            numberOfAlternativeAddresses
            coverMediaUrl
            coverMediaType
            measurementSystem
            currencyCode
            buildingSize
            buildingSizeMt
            country
            state
            latitude
            longitude
            latitudeDisplay
            longitudeDisplay
            longLeaseHolders {
              id
              name
              website
              logoUrl
            }
            owners {
              id
              name
              website
              logoUrl
            }
            market {
              id
              name
            }
            submarket {
              id
              name
            }
            micromarket {
              id
              name
            }
            propertyType {
              id
              name
            }
            propertyClass {
              id
              name
            }
          }
          markets {
            id
            name
            latitude
            longitude
            mediaUrl
            country {
              id
              name
            }
          }
          scoopComments {
            id
            comment
            createdAt
            userId
            user {
              id
              firstName
              lastName
              email
            }
          }
        }
        lease {
          ...leaseInfo
        }
        sale {
          ...saleBasicData
        }
        propertyPriceQuote {
          ...priceQuoteInfo
        }
        tenantInMarketId
        TIM {
          ...TIMFragment
        }
      }
    }
  }
  ${PRICE_QUOTE_INFO_FRAGMENT}
  ${LEASE_INFO_FRAGMENT}
  ${SALE_BASIC_DATA_FRAGMENT}
  ${TIM_FRAGMENT}
`;
