import React, { FC, ReactNode, HTMLAttributes, forwardRef } from 'react';
import classnames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import styles from './DragAndDrop.module.scss';

export type SortableItemProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
};

export const SortableItem: FC<SortableItemProps> = forwardRef<
  HTMLDivElement,
  SortableItemProps
>(({ id, children, style, ...props }) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id! });

  const combinedStyle = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    ...style,
  };

  return (
    <div
      id={id}
      ref={setNodeRef}
      style={combinedStyle}
      className={classnames(styles['item'], {
        [styles['item-grabbing']]: isDragging,
      })}
      {...props}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
});
