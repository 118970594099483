import dayjs from 'dayjs';
import { min, round } from 'lodash';

import dateFormats from 'constants/dateFormats';
import { MAX_YEARS_OF_DATA } from 'components/Graphs/RecentLeases/graphConstants';
import {
  IQuarterDateItem,
  IYearDateItem,
} from 'components/Graphs/RecentLeases/interfaces';

import { applyDayJSPlugins } from './dayjsPlugins';

applyDayJSPlugins();

export const getTodayUTCTime = () => {
  return dayjs()
    .startOf('day')
    .utc()
    .toDate()
    .getTime();
};

export const getDateISOString = (date?: number | string) =>
  dayjs(date)
    .utc()
    .format(dateFormats.ISO_DATE);

export const getShortDate = (date?: number | string) =>
  dayjs(date)
    .utc()
    .format(dateFormats.SHORT_DATE);

export const getTodayDateISOString = () =>
  dayjs(undefined, dateFormats.ISO_DATE)
    .utc()
    .format(dateFormats.ISO_DATE);

export const getTodayUTCTimeString = () => {
  return dayjs()
    .startOf('day')
    .utc()
    .toDate()
    .toISOString();
};

export const addYearsDateUTCTime = (yearsToAdd: number) => {
  return dayjs()
    .startOf('day')
    .utc()
    .add(yearsToAdd, 'year')
    .toDate()
    .getTime();
};

export const addMonthssDateUTCTime = (monthsToAdd: number) => {
  return dayjs()
    .startOf('day')
    .utc()
    .add(monthsToAdd, 'month')
    .toDate()
    .getTime();
};

export const isDateWithinLimit = (
  dateValue: string,
  limit: number,
  dateType: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year',
) => dayjs(dateValue).isBefore(dayjs().add(limit, dateType));

export const isDateAfter = (
  dateValue: string,
  limit: number,
  dateType: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year',
) => dayjs().isAfter(dayjs(dateValue).add(limit, dateType));

export const getYearFromDate = (date?: string) =>
  dayjs(date)
    .utc()
    .format('YYYY');

export const getDateFromYear = (year: number | string) => {
  // @TODO:
  //In some cases the year arg is receiving a full date value and breaking some charts.
  //The substring bellow is to be sure that we will only use the year 4 characters.
  const result = dayjs(`${year.toString().substring(0, 4)}-01-01`).utc();
  return result;
};

export const getDateFromYearMonth = (year: number, month: string) =>
  dayjs(`${year}-${month}-01`).utc();

export const QUARTER_MONTHS = ['01', '04', '07', '10'];

export const getQuarterDates = (
  now = new Date(),
  maxYears = MAX_YEARS_OF_DATA,
): IQuarterDateItem[] => {
  const currentYear = now.getUTCFullYear();
  const startYear = currentYear - maxYears;

  const currentQuarter = Math.floor((now.getUTCMonth() + 3) / 3);

  const dates: IQuarterDateItem[] = [];

  for (let y = startYear; y <= currentYear; y++) {
    for (let q = 0; q < (y === currentYear ? currentQuarter : 4); q++) {
      const month = QUARTER_MONTHS[q];

      dates.push({
        dateStr: `${y}-${month}-01`,
        year: y,
        quarter: q + 1,
      });
    }
  }

  return dates;
};

export const getYearsDates = (
  now = new Date(),
  maxYears = MAX_YEARS_OF_DATA,
): IYearDateItem[] => {
  const currentYear = now.getUTCFullYear();
  const startYear = currentYear - maxYears;

  const dates: IYearDateItem[] = [];

  for (let y = startYear; y <= currentYear; y++) {
    dates.push({
      dateStr: `${y}-01-01`,
      year: y,
      quarter: 1,
    });
  }

  return dates;
};

export const getDateFromNow = (time: string | number | Date) => {
  return dayjs(time).fromNow();
};

export const getUTCTimeFromDate = (date?: string | number) => {
  return dayjs(date)
    .startOf('day')
    .utc()
    .toDate()
    .getTime();
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const getFormatedTime = (date?: string | number) => {
  return dayjs(date)
    .utc()
    .format('hh:mm a');
};

export const getQuarterFromDate = (date: string) => {
  return Math.floor((dayjs(date).month() + 3) / 3);
};

export const getRemainingCurrentYearQuarters = () => {
  const currentQuarter = dayjs(new Date()).quarter();
  return 4 - currentQuarter;
};

/**
 * Passing a date and a list of quarters, it returns true if the date
 * is the first quarter of its year comparing using the quarters list.
 */
export const isTheFirstDateInTheQuarter = ({
  date,
  quarterItems,
}: {
  date: any;
  quarterItems: { year: number; quarter: number }[];
}) => {
  const dateQuarter = getQuarterFromDate(date);
  const dateYear = Number(getYearFromDate(date));
  const quartersFromDateYear = quarterItems
    .filter(item => item.year === dateYear)
    .map(item => item.quarter);
  const minQuarter = min(quartersFromDateYear);
  return dateQuarter === minQuarter;
};

export const currentYear = new Date().getFullYear();

export const getQuarterStartEndDates = (
  year: number,
  quarter: number,
): {
  start: number;
  end: number;
} => {
  const startDate = new Date(
    `${year}-${QUARTER_MONTHS[quarter - 1]}-01T00:00:00Z`,
  );

  const nextQuarter = quarter < 4 ? quarter + 1 : 1;
  const nextYear = quarter < 4 ? year : year + 1;
  const endDate = new Date(
    `${nextYear}-${QUARTER_MONTHS[nextQuarter - 1]}-01T00:00:00Z`,
  );
  endDate.setUTCMilliseconds(endDate.getUTCMilliseconds() - 1);

  return { start: startDate.getTime(), end: endDate.getTime() };
};

export const excludeQuarterSymbol = (quarter: string) => {
  return parseInt(quarter.slice(1), 10);
};

export const monthsToYears = (months: number): number => {
  return round(months / 12);
};

export const isValidDate = (value: string, format = dateFormats.ISO_DATE) => {
  // Validate value in several steps
  // 1. When date picker is filled completely by day, month, year (length of numbers should match with length of format numbers)
  // 2. When we do not exceed the minimum and maximum limits for the years. (1800 - current year + 200)
  // 3. When date is valid
  const currentYear = new Date().getUTCFullYear();
  const limitYearValidation = { min: 1800, max: currentYear + 200 };
  const requiredNumberCount = 8;

  const matches = value?.match(/\d/g) ?? [];
  const day = parseInt(value.split('-')[2], 10);
  const month = parseInt(value.split('-')[1], 10);
  const year = parseInt(value.split('-')[0], 10);

  if (!matches.length) return true; // Return true if empty
  if (value.length !== format.length) return false; // Check length against format

  // Check year limits
  if (year < limitYearValidation.min || year > limitYearValidation.max)
    return false;

  const date = dayjs(value, format, true);

  return (
    requiredNumberCount === matches.length &&
    date.isValid() &&
    date.date() === day &&
    date.month() + 1 === month
  );
};
