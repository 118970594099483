import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import {
  CompSetOrderContext,
  ICompSetOrder,
} from 'contexts/CompSetOrderContext';

import { SortableItem } from './SortableItem';
import styles from './DragAndDrop.module.scss';

type DragAndDropProps<T> = {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  saveContextOrderType?: keyof ICompSetOrder;
};

export interface Item {
  id: number;
}

export const DragAndDrop = <T extends Item>({
  items: initialItems,
  renderItem,
  saveContextOrderType = 'compSet',
}: DragAndDropProps<T>) => {
  const [items, setItems] = useState(initialItems);

  const [activeId, setActiveId] = useState<number | null>(null);

  const { setCompSetOrder } = useContext(CompSetOrderContext);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 1 },
    }),
    useSensor(TouchSensor),
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(+event.active.id);
  }, []);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems(currentItems => {
        const oldIndex = currentItems.findIndex(item => item.id === active.id);
        const newIndex = currentItems.findIndex(item => item.id === over?.id);
        return arrayMove(currentItems, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const orderedItems = useMemo(() => {
    return items.map((i, idx) => ({
      propertyId: i.id,
      order: idx,
    }));
  }, [items]);

  useEffect(() => {
    const itemsLengthChanged = items.length !== initialItems.length;
    if (itemsLengthChanged) {
      setItems(initialItems);
    }
  }, [initialItems, items.length]);

  useEffect(() => {
    if (!orderedItems.length) return;
    setCompSetOrder((prevCompSetOrder: ICompSetOrder) => ({
      ...prevCompSetOrder,
      [saveContextOrderType]: orderedItems,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedItems]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext
        items={items.map(item => item.id)}
        strategy={rectSortingStrategy}
      >
        <div className={styles['items-container']}>
          {items.map((item, index) => (
            <SortableItem key={item.id} id={(item.id as unknown) as string}>
              {renderItem(item, index)}
            </SortableItem>
          ))}
        </div>
      </SortableContext>
      <DragOverlay adjustScale className={styles['drag-overlay']}>
        {activeId &&
          renderItem(
            items.find(item => +item.id === activeId) as T,
            items.findIndex(item => +item.id === activeId),
          )}
      </DragOverlay>
    </DndContext>
  );
};
