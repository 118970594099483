import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { startCase } from 'lodash';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH,
  I18N_AVANT_PROPERTY_ATTR_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH,
  I18N_PLATFORM_ROLES_COMPANY_PROMPT_PATH,
} from 'constants/i18n';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import CompanySearchCombo from 'components/CreateComps/CompanySearchCombo';
import FormControl from 'components/FormControl';
import RemoteChoiceInput from 'components/Inputs/RemoteChoiceInput';
import {
  PROPERTY_CLASSES_QUERY,
  PROPERTY_TYPES_ENTITIES_QUERY,
} from 'graphql/property';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import MultiColumnDropdownFilter from 'components/MultiColumnDropdownFilter';
import { MARKETS_QUERY, SUB_MARKETS_QUERY } from 'graphql/markets';
import { IdName } from 'interfaces/IdName';
import { filterItemsByName } from 'utils/filters';
import { getUniqueSubmarketsByName } from 'utils/markets';
import TextInput from 'components/Inputs/TextInput';
import LeaseSearchCombo from 'components/LeaseSearchCombo';
import DateInput from 'components/Inputs/DateInput';
import { SIZE_GROWTH_OPTIONS } from 'constants/expandingContracting';
import UpdateSummary, { getUpdateSummaryData } from 'components/UpdateSummary';
import { getDateISOString } from 'utils/date';
import { SEARCH_COMPANIES_PROPERTIES_QUERY } from 'graphql/common';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import RadioInput from 'components/Inputs/RadioInput';
import FormFooter from 'components/FormFooter';
import { ITIM } from 'interfaces/ITIM';
import { TIM_STATUSES_QUERY } from 'graphql/tims/queries';
import { authContext } from 'contexts/AuthContext';
import { DOCK_CONFIGURATION_OPTIONS } from 'constants/dockConfigutations';
import { convertToNumber } from 'utils/parsers/convertToNumber';
import {
  formatNumberWithCommas,
  removeCommaFromNumber,
} from 'utils/formatters/number';
import { useCalculateSizeGrowth } from './useCalculateSizeGrowth';
import Comments from '../FormFields/Comments';
import SelectProperty from '../FormFields/SelectProperty';
import { useSubtypes } from '../PropertyForm/usePropertyForm';
import { COMPANIES_SUGGESTIONS } from '../FormFields/constants';
import { getLastDiscussedDateSuggestions } from '../LeaseForm/formDates';
import styles from '../FormSection.module.scss';

import { orderByNameAsc } from './constants';
import { YES_NO_OPTIONS } from 'constants/commonOptions';
import { updateBooleanOptions } from '../formUtils';

export interface Props {
  tim: ITIM;
  setTim: React.Dispatch<any>;
  onSubmit: () => void;
  isLoading?: boolean;
  submitLabel?: string;
  unitOfMeasurement?: string;
  isUpdate?: boolean;
  onCancel?: () => void;
  setUnitOfMeasurement?: (value: string) => void;
  handleErrorMessage?: (message: string) => void;
}

const TenantInMarketForm: React.FC<Props> = props => {
  const {
    tim,
    setTim,
    isLoading,
    unitOfMeasurement,
    setUnitOfMeasurement,
  } = props;
  const { user } = useContext(authContext);

  const updateSummaryValue = getUpdateSummaryData(
    tim.createdAt ?? '',
    tim.createdUser,
    tim.updatedAt ?? '',
    tim.updatedUser,
  );
  const displayCreateUpdateSection =
    updateSummaryValue?.created?.date || updateSummaryValue?.modified?.date;

  const [property, setProperty] = useState<IPropertyInput | null>({});
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [timTenantCompany, setTimTenantCompany] = useState<string>('');
  const [extraFields, setExtraFields] = useState<boolean>(false);

  const isExitDateDisabled = !!tim.timStatusTypeId
    ? tim.timStatusTypeId == 1 || tim.timStatusTypeId == 2
    : true;

  const { get: getSubtypes, data: dataSubtypes } = useSubtypes();
  const propertySubtypes = useMemo(() => dataSubtypes?.propertySubtypes || [], [
    dataSubtypes,
  ]);

  const getSuggestedMarketOptions = useCallback(
    (items: any) => {
      const marketIds = new Set(user.preferences?.marketIds ?? []);
      return items.filter((obj: any) => marketIds.has(obj.id));
    },
    [user.preferences?.marketIds],
  );
  const loadSubtypesByType = async (typeId?: number) => {
    if (!typeId) {
      return;
    }

    getSubtypes({
      variables: {
        search: {
          typeId,
        },
      },
    });
  };

  const updateField = useCallback(
    (fieldName: string, fieldValue: any) => {
      if (fieldName === 'targetPropertyTypeId') {
        if (fieldValue == 2 || fieldValue == 3 || fieldValue == 12) {
          setExtraFields(true);
        } else {
          setExtraFields(false);
        }
      }
      setTim((value: any) => ({ ...value, [fieldName]: fieldValue }));
      setIsDirty(true);
    },
    [setTim],
  );

  const {
    handleChangedSizeGrowth,
    handleTriggeredSizeGrowth,
  } = useCalculateSizeGrowth({
    tim,
    updateField,
    unitOfMeasurement,
  });

  const getLeaseSearchPlaceholder = () => {
    if (!tim.currentMarket || !tim.currentMarket.id || !tim.currentPropertyId) {
      return '';
    }
    if (!tim.currentLeaseId) {
      return translateText('avantPlatform.common.words.selectCurrentLease');
    }
    return `${property?.name ?? tim.currentProperty?.primaryAddress} · ${tim
      .tenantCompany?.name ?? timTenantCompany}`;
  };

  useEffect(() => {
    if (
      (!!tim.tenantCompany?.id || !!tim.tenantCompanyId) &&
      (!!tim.targetMarketId || !!tim.targetMarket?.id) &&
      !!tim.targetPropertyTypeId &&
      (!!tim.targetSizeMax || !!tim.targetSizeMaxMt) &&
      (!!tim.targetSizeMin || !!tim.targetSizeMinMt) &&
      !!tim.timStatusTypeId &&
      !!tim.activeSinceDate
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [tim]);

  useEffect(() => {
    if (tim.targetPropertyTypeId) {
      if (
        tim.targetPropertyTypeId == 2 ||
        tim.targetPropertyTypeId == 3 ||
        tim.targetPropertyTypeId == 12
      ) {
        setExtraFields(true);
      }
    }
    getSubtypes({
      variables: {
        search: { typeIds: [tim.targetPropertyTypeId] },
      },
    });
  }, [tim, getSubtypes]);

  return (
    <>
      <div className={styles.container}>
        <CompanySearchCombo
          isRequired={true}
          label={startCase(
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.tenant`),
          )}
          btnLabel={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.createTenant`,
          )}
          placeholder={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.enterTenantName`,
          )}
          wrapperClassName={styles['form-row']}
          inputId={'lease-tenant-company-input-id'}
          companies={
            !!tim.tenantCompany
              ? [{ id: tim.tenantCompany!.id, name: tim.tenantCompany!.name }]
              : []
          }
          onCompanySelection={(value: any) => {
            if (!value) {
              setTimTenantCompany('');
              updateField('tenantCompanyId', null);
              updateField('tenantCompany', null);
            } else {
              const newValue = value?.custom || value;
              setTimTenantCompany(newValue.name);
              updateField('tenantCompanyId', newValue.id);
            }
          }}
          aliasPlaceholder={(suggestionName: string) => {
            const clickToTagATenant = `${I18N_PLATFORM_COMMON_WORD_PATH}.clickToTagATenant`;
            return `${translateText(clickToTagATenant, {
              suggestionName: suggestionName,
            })}.`;
          }}
        />
        <FormControl
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.propertyType`,
          )}
          wrapperClassName={styles['form-row']}
          required
          isMultiline
        >
          <RemoteChoiceInput
            query={PROPERTY_TYPES_ENTITIES_QUERY}
            queryParser={data => data?.propertyTypesEntities || []}
            selectedItem={
              !!tim.targetPropertyTypeId ? { id: tim.targetPropertyTypeId } : {}
            }
            containerWrapperClassName={
              styles['radio-input-container-multiline']
            }
            onChange={value => {
              if (!value) {
                updateField('targetPropertyTypeId', null);
              } else {
                updateField('targetPropertyTypeId', value!.id);
                loadSubtypesByType(value!.id);
              }
            }}
          />
        </FormControl>
        {!!tim.targetPropertyTypeId && (
          <FormControl
            label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.subtype`)}
            wrapperClassName={styles['form-row']}
          >
            <ChoiceInput
              selectedItem={
                !!tim.targetPropertySubTypeId
                  ? { id: tim.targetPropertySubTypeId }
                  : {}
              }
              data={propertySubtypes}
              containerWrapperClassName={
                styles['radio-input-container-multiline']
              }
              onChange={value => {
                updateField('targetPropertySubTypeId', value?.id ?? undefined);
              }}
            />
          </FormControl>
        )}
        <FormControl
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.targetMarket`,
          )}
          wrapperClassName={styles['form-row']}
          required={true}
        >
          <MultiColumnDropdownFilter
            label={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.selectMarket`,
            )}
            placeholder={translateText(
              `${I18N_AVANT_PROPERTY_ATTR_PATH}.property.prompt.searchMarket`,
            )}
            isSearchVisible={true}
            fullWidth={true}
            isRequired={true}
            graphqlQuery={MARKETS_QUERY}
            mapper={data => data.markets}
            selectedValue={
              !!tim.targetMarket?.id
                ? { id: tim.targetMarket.id, name: tim.targetMarket?.name }
                : undefined
            }
            isFilterActive={false}
            onChangeFullOptionObject={fullOptionObject => {
              setUnitOfMeasurement?.(
                fullOptionObject?.country?.areaMeasurement,
              );
            }}
            onChange={(selectedMarketOptions: IdName) => {
              updateField('targetMarket', selectedMarketOptions);
              updateField('targetMarketId', selectedMarketOptions?.id ?? null);
              updateField('targetSubmarkets', null);
            }}
            clientSideFilter={filterItemsByName}
            graphqlVariables={() => ({
              search: {
                order: orderByNameAsc,
              },
            })}
            isMultipleSelect={false}
            groupBySubfield="country"
            getSuggestedOptions={getSuggestedMarketOptions}
            optionsLabels={{
              all: translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.allMarkets`,
              ),
              suggested: translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.suggestedMarkets`,
              ),
            }}
            groupItemsPathParams={{
              sortByField: 'country.id',
              displaySortedField: 'country.name',
            }}
          />
        </FormControl>
        <FormControl
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.targetSubmarket`,
          )}
          wrapperClassName={styles['form-row']}
        >
          <MultiColumnDropdownFilter
            label={translateText(
              `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.selectSubmarket`,
            )}
            placeholder={translateText(
              `${I18N_AVANT_PROPERTY_ATTR_PATH}.property.prompt.searchSubmarket`,
            )}
            isSearchVisible={true}
            fullWidth={true}
            isRequired={true}
            graphqlQuery={SUB_MARKETS_QUERY}
            mapper={data => getUniqueSubmarketsByName(data?.submarkets)}
            selectedValue={
              !!tim.targetSubmarkets ? tim.targetSubmarkets : undefined
            }
            isFilterActive={false}
            onChange={(selectedMarketOptions: IdName[] = []) => {
              updateField('targetSubmarkets', selectedMarketOptions);
            }}
            clientSideFilter={filterItemsByName}
            graphqlVariables={() => ({
              search: {
                filter: {
                  markets: [{ id: tim.targetMarketId ?? tim.targetMarket?.id }],
                  propertyTypeIds: tim.targetPropertyTypeId
                    ? [tim.targetPropertyTypeId]
                    : undefined,
                },
                order: orderByNameAsc,
              },
            })}
            isMultipleSelect={true}
            groupBySubfield="country"
            isDisabled={!tim.targetMarketId && !tim.targetMarket?.id}
          />
        </FormControl>
        <FormControl
          required={true}
          customRequiredText={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.minMaxRequired`,
          )}
          label={`Target Size${unitOfMeasurement === 'sqm' ? '' : '(sf)'}`}
          wrapperClassName={styles['form-row']}
        >
          <TextInput
            formatter={value => Number(value).toLocaleString()}
            value={
              tim[
                unitOfMeasurement === 'sqm'
                  ? 'targetSizeMinMt'
                  : 'targetSizeMin'
              ] ?? undefined
            }
            id="target-size-min"
            onChange={value => {
              updateField(
                unitOfMeasurement === 'sqm'
                  ? 'targetSizeMinMt'
                  : 'targetSizeMin',
                convertToNumber(value) || null,
              );
            }}
            onClear={() =>
              updateField(
                unitOfMeasurement === 'sqm'
                  ? 'targetSizeMinMt'
                  : 'targetSizeMin',
                null,
              )
            }
            placeholder={'---'}
          />
          <span className={styles['input-helper']}>to</span>
          <TextInput
            formatter={value => Number(value).toLocaleString()}
            value={
              tim[
                unitOfMeasurement === 'sqm'
                  ? 'targetSizeMaxMt'
                  : 'targetSizeMax'
              ] ?? undefined
            }
            id="target-size-max"
            onChange={value => {
              updateField(
                unitOfMeasurement === 'sqm'
                  ? 'targetSizeMaxMt'
                  : 'targetSizeMax',
                convertToNumber(value) || null,
              );
              handleTriggeredSizeGrowth();
            }}
            onClear={() =>
              updateField(
                unitOfMeasurement === 'sqm'
                  ? 'targetSizeMaxMt'
                  : 'targetSizeMax',
                null,
              )
            }
            placeholder={'---'}
          />
        </FormControl>
        <FormControl
          label={translateText(
            'avantPlatform.attributes.property.label.status',
          )}
          required
          wrapperClassName={styles['form-row']}
        >
          <div className={styles['wrapper-radio-container']}>
            <RemoteChoiceInput
              selectedItem={
                !!tim.timStatusTypeId ? { id: tim.timStatusTypeId } : {}
              }
              query={TIM_STATUSES_QUERY}
              queryParser={data => data?.timStatusTypes}
              containerWrapperClassName={styles['radio-input-container']}
              itemWrapperClassName={styles['button-input-item']}
              onChange={value => {
                if (!!value) {
                  if (value.id == 1 && !tim.activeSinceDate) {
                    updateField(
                      'activeSinceDate',
                      dayjs().format('YYYY-MM-DD'),
                    );
                  }
                  if (
                    value.id == 3 ||
                    value.id == 4 ||
                    value.id == 5 ||
                    value.id == 6
                  ) {
                    updateField('exitDate', dayjs().format('YYYY-MM-DD'));
                    updateField('timStatusTypeId', value!.id);
                  }
                  updateField('timStatusTypeId', value!.id);
                  updateField('lastDiscussedDate', dayjs(new Date()));
                } else {
                  updateField('timStatusTypeId', null);
                }
              }}
            />
          </div>
        </FormControl>
        <FormControl
          required
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.activeSinceDate`,
          )}
          wrapperClassName={styles['form-row']}
        >
          <div className={styles['multiple-dates']}>
            <DateInput
              id="active-since-date"
              value={
                tim.activeSinceDate && getDateISOString(tim.activeSinceDate)
              }
              onChange={value => updateField('activeSinceDate', value)}
            />
            <span className={styles['input-helper']}>
              {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.exitDate`)}
            </span>
            <DateInput
              id="exit-date"
              isDisabled={isExitDateDisabled}
              value={tim.exitDate && getDateISOString(tim.exitDate)}
              onChange={value => updateField('exitDate', value)}
            />
          </div>
        </FormControl>
        <FormControl
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.targetCommencementDate`,
          )}
          wrapperClassName={styles['form-row']}
        >
          <DateInput
            id="target-commencement-date"
            value={
              tim.targetCommencementDate &&
              getDateISOString(tim.targetCommencementDate)
            }
            onChange={value => updateField('targetCommencementDate', value)}
          />
        </FormControl>
        <FormControl
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.propertyClass`,
          )}
          wrapperClassName={styles['form-row']}
        >
          <RemoteChoiceInput
            query={PROPERTY_CLASSES_QUERY}
            queryParser={data => data?.propertyClasses || []}
            selectedItem={
              !!tim.targetThirdPartyPropertyClassId
                ? { id: tim.targetThirdPartyPropertyClassId }
                : undefined
            }
            containerWrapperClassName={styles['radio-input-container']}
            onChange={item =>
              updateField('targetThirdPartyPropertyClassId', item?.id)
            }
          />
        </FormControl>
        {extraFields && (
          <>
            <FormControl
              wrapperClassName={styles['form-row']}
              label={translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.typicalClearHeight`,
              )}
            >
              <span className={styles['input-helper']}>
                {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.low`) + ':'}
              </span>
              <TextInput
                value={
                  tim[
                    unitOfMeasurement === 'sqm'
                      ? 'clearHeightLowMt'
                      : 'clearHeightLow'
                  ] || ''
                }
                isNarrow={true}
                id="lease-floors"
                onChange={value =>
                  updateField(
                    unitOfMeasurement === 'sqm'
                      ? 'clearHeightLowMt'
                      : 'clearHeightLow',
                    parseInt(value),
                  )
                }
                placeholder={'00'}
              />
              <span className={styles['input-helper']}>
                {unitOfMeasurement === 'sqm'
                  ? ''
                  : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.feet`)}
              </span>
              <span className={styles['input-helper']}>
                {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.high`) + ':'}
              </span>
              <TextInput
                value={
                  tim[
                    unitOfMeasurement === 'sqm'
                      ? 'clearHeightHighMt'
                      : 'clearHeightHigh'
                  ] || ''
                }
                isNarrow={true}
                id="lease-floors"
                onChange={value =>
                  updateField(
                    unitOfMeasurement === 'sqm'
                      ? 'clearHeightHighMt'
                      : 'clearHeightHigh',
                    parseInt(value),
                  )
                }
                placeholder={'00'}
              />
              <span className={styles['input-helper']}>
                {unitOfMeasurement === 'sqm'
                  ? ''
                  : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.feet`)}
              </span>
            </FormControl>
            <FormControl
              wrapperClassName={styles['form-row']}
              label={translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.driveInDoors`,
              )}
            >
              <TextInput
                value={tim.driveInDoors || ''}
                id="lease-suite"
                onChange={value => updateField('driveInDoors', parseInt(value))}
                placeholder={translateText(
                  `${I18N_PLATFORM_COMMON_WORD_PATH}.enterDoors`,
                )}
              />
            </FormControl>
            <FormControl
              wrapperClassName={styles['form-row']}
              label={translateText(
                `${I18N_AVANT_PROPERTY_ATTR_PATH}.property.label.loadingDocks`,
              )}
            >
              <TextInput
                value={tim.loadingDocks || ''}
                id="lease-suite"
                onChange={value => updateField('loadingDocks', parseInt(value))}
                placeholder={translateText(
                  `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterDocks`,
                )}
              />
            </FormControl>
            <FormControl
              label={translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.dockConfiguration`,
              )}
              wrapperClassName={styles['form-row']}
            >
              <div className={styles['wrapper-radio-container']}>
                <ChoiceInput
                  selectedItem={
                    !!tim.dockConfigurationTypeId
                      ? { id: tim.dockConfigurationTypeId }
                      : undefined
                  }
                  data={DOCK_CONFIGURATION_OPTIONS}
                  containerWrapperClassName={styles['radio-input-container']}
                  itemWrapperClassName={styles['button-input-item']}
                  onChange={item => {
                    updateField('dockConfigurationTypeId', item?.id);
                  }}
                />
              </div>
            </FormControl>
            <FormControl
              wrapperClassName={styles['form-row']}
              label={translateText(
                `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.trailerParkingSpaces`,
              )}
            >
              <TextInput
                value={tim.trailerParkingSpaces || ''}
                id="lease-floors"
                onChange={item => {
                  updateField('trailerParkingSpaces', parseInt(item));
                }}
                placeholder={translateText(
                  'avantPlatform.attributes.property.prompt.enterSpaces',
                )}
              />
            </FormControl>
          </>
        )}
      </div>
      <div className={classNames(styles.container)}>
        <FormControl
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.currentMarket`,
          )}
          wrapperClassName={styles['form-row']}
        >
          <MultiColumnDropdownFilter
            label={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.selectMarket`,
            )}
            placeholder={translateText(
              `${I18N_AVANT_PROPERTY_ATTR_PATH}.property.prompt.searchMarket`,
            )}
            isSearchVisible={true}
            fullWidth={true}
            graphqlQuery={MARKETS_QUERY}
            mapper={data => data.markets}
            selectedValue={
              !!tim.currentMarket?.id
                ? { id: tim.currentMarket.id, name: tim.currentMarket?.name }
                : undefined
            }
            isFilterActive={false}
            onChange={(selectedMarketOptions: IdName) => {
              updateField('currentMarket', selectedMarketOptions ?? null);
            }}
            clientSideFilter={filterItemsByName}
            graphqlVariables={() => ({
              search: {
                order: orderByNameAsc,
              },
            })}
            isMultipleSelect={false}
            getSuggestedOptions={getSuggestedMarketOptions}
            optionsLabels={{
              all: translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.allMarkets`,
              ),
              suggested: translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.suggestedMarkets`,
              ),
            }}
            groupItemsPathParams={{
              sortByField: 'country.id',
              displaySortedField: 'country.name',
            }}
            groupBySubfield="country"
          />
        </FormControl>
        <SelectProperty
          id="lease-property-input-id"
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.currentAddress`,
          )}
          value={{
            id: tim?.currentPropertyId ?? undefined,
            primaryAddress: tim?.currentProperty?.primaryAddress,
            name: tim?.currentProperty?.name,
          }}
          graphqlParams={{
            query: SEARCH_COMPANIES_PROPERTIES_QUERY,
            field: 'name',
            resultDataName: 'propertiesCompanies',
            onlyProperties: true,
          }}
          parseResults={(data: IPropertyCompany[]): IPropertyCompany[] => {
            return data.map(propertyCompany => ({
              ...propertyCompany,
              name: propertyCompany.displayAddress || propertyCompany.name,
            }));
          }}
          onChange={(value: any) => {
            setProperty(value);
            updateField('currentPropertyId', value.propertyId);
          }}
          onChangeText={(text: any) => {
            if (!text.length) {
              updateField('currentPropertyId', null);
            }
          }}
          onClear={() => {
            updateField('currentPropertyId', null);
            updateField('currentLeaseId', null);
          }}
        />
        <LeaseSearchCombo
          label={translateText('avantPlatform.common.words.currentLease')}
          wrapperClassName={styles['form-row']}
          inputId={'tims-lease-input-id'}
          isDisabled={
            !(
              !!tim.currentMarket &&
              !!tim.currentMarket.id &&
              !!tim.currentPropertyId
            )
          }
          disabledPlaceholder={translateText(
            'avantPlatform.common.words.selectCurrentLease',
          )}
          placeholder={getLeaseSearchPlaceholder()}
          tenantCompanyId={tim.tenantCompanyId ?? tim.tenantCompany?.id!}
          currentPropertyId={tim.currentPropertyId!}
          currentMarketId={tim.currentMarket?.id!}
          currentLeaseId={tim.currentLeaseId ?? null}
          onInputClear={
            tim?.currentLeaseId
              ? () => {
                  updateField('currentLeaseId', null);
                }
              : undefined
          }
          onLeaseSelection={(value: any) => {
            const newValue = value?.custom || value;

            if (!tim?.exitDate && newValue.expirationDate) {
              updateField('exitDate', newValue.expirationDate);
            }
            updateField('currentLeaseId', newValue.id);
            updateField('currentSize', newValue.size);
          }}
        />
        <FormControl
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.currentSize`)}
          wrapperClassName={styles['form-row']}
        >
          <TextInput
            id="buildingSizeInput"
            formatter={value => formatNumberWithCommas(value)}
            value={
              unitOfMeasurement === 'sqm'
                ? tim.currentSizeMt ?? ''
                : tim.currentSize ?? ''
            }
            placeholder={translateText(
              'avantPlatform.attributes.property.prompt.enterSize',
            )}
            onChange={item => {
              if (unitOfMeasurement === 'sqm') {
                updateField(
                  'currentSizeMt',
                  item ? parseInt(removeCommaFromNumber(item)) : null,
                );
                updateField('currentSize', null);
              } else {
                updateField(
                  'currentSize',
                  item ? parseInt(removeCommaFromNumber(item)) : null,
                );
                updateField('currentSizeMt', null);
              }
              handleTriggeredSizeGrowth();
            }}
          />
          <span className={styles['size-spaced']}>{unitOfMeasurement}</span>
        </FormControl>
        <FormControl
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.sizeGrowth`)}
          wrapperClassName={styles['form-row']}
        >
          <ChoiceInput
            data={SIZE_GROWTH_OPTIONS}
            itemWrapperClassName={styles['form-choice-input']}
            selectedItem={(tim.expandingContracting as IdName) ?? null}
            labelFieldName="name"
            onChange={item => {
              updateField('expandingContracting', item);
              handleChangedSizeGrowth();
            }}
          />
        </FormControl>
        <FormControl
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.newToMarket`)}
          wrapperClassName={styles['form-row']}
        >
          <ChoiceInput
            data={YES_NO_OPTIONS}
            itemWrapperClassName={styles['form-choice-input']}
            selectedItem={{
              name: tim.newToMarket,
            }}
            labelFieldName="id"
            onChange={item =>
              updateField('newToMarket', updateBooleanOptions(item))
            }
          />
        </FormControl>
      </div>
      <div
        className={classNames(styles.container, {
          [styles['is-last']]: !displayCreateUpdateSection,
        })}
      >
        <CompanySearchCombo
          btnLabel={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.createTenant`,
          )}
          label={translateText(
            `${I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH}.tenantRepBrokerCompany`,
          )}
          inputId={'landlord-broker-input'}
          placeholder={translateText(
            `${I18N_PLATFORM_ROLES_COMPANY_PROMPT_PATH}.enterBrokerName`,
          )}
          wrapperClassName={styles['form-row']}
          companies={[
            {
              name: tim.tenantRepBrokerCompany?.name,
              id: tim.tenantRepBrokerCompany?.id,
            },
          ]}
          aliasPlaceholder={(suggestionName: string) =>
            `Click to tag ${suggestionName} as a Landlord Broker.`
          }
          onCompanySelection={item => {
            if (!item) {
              updateField('tenantRepBrokerCompany', null);
              updateField('tenantRepBrokerName', null);
              updateField('tenantRepBroker', null);
            } else {
              updateField('tenantRepBrokerCompany', {
                id: item?.id,
                name: item?.name,
              });
            }
          }}
          onClearSelectedSuggestion={() => null}
        />
        <FormControl wrapperClassName={styles['secondary-row']} indented>
          <RadioInput
            selectedItem={{
              id: tim.tenantRepBrokerCompany?.id,
              value: tim.tenantRepBrokerCompany?.name,
            }}
            wrapperClassName={styles['broker-helpers']}
            data={COMPANIES_SUGGESTIONS}
            onChange={item => {
              updateField('tenantRepBrokerCompany', {
                id: item?.id,
                name: item?.value,
              });
            }}
          />
        </FormControl>
        {!!tim.tenantRepBrokerCompany && (
          <FormControl
            label={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.tenantRepBrokerContact`,
            )}
            wrapperClassName={styles['form-row']}
          >
            <TextInput
              value={tim.tenantRepBrokerName ?? tim.tenantRepBroker?.name}
              labelClassName={styles['source-label']}
              inputClassName={styles['source-input']}
              placeholder={translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.enterTenantRepBrokerContact`,
              )}
              fullWidth
              isLarge={true}
              onClear={() => updateField('tenantRepBrokerName', '')}
              onChange={item => updateField('tenantRepBrokerName', item)}
            />
          </FormControl>
        )}
        <FormControl
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.source`)}
          wrapperClassName={styles['form-row']}
        >
          <TextInput
            value={tim.source || ''}
            labelClassName={styles['source-label']}
            inputClassName={styles['source-input']}
            placeholder={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.enterSource`,
            )}
            isLarge={true}
            onClear={() => updateField('source', '')}
            onChange={item => updateField('source', item)}
          />
        </FormControl>
        <Comments
          field="comments"
          value={tim.comments || ''}
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.comments`)}
          onChange={updateField}
        />
        <FormControl
          label={translateText(
            'avantPlatform.attributes.lease.label.lastDiscussedDate',
          )}
          wrapperClassName={styles['form-row']}
        >
          <div className={styles['large-input-container']}>
            <DateInput
              id="last-discussed-date"
              value={
                tim.lastDiscussedDate && getDateISOString(tim.lastDiscussedDate)
              }
              onChange={item => updateField('lastDiscussedDate', item)}
            />

            <RadioInput
              selectedItem={
                tim.lastDiscussedDate && getDateISOString(tim.lastDiscussedDate)
              }
              data={getLastDiscussedDateSuggestions()}
              onChange={item => updateField('lastDiscussedDate', item.value)}
            />
          </div>
        </FormControl>
      </div>
      {displayCreateUpdateSection && (
        <UpdateSummary
          data={updateSummaryValue}
          wrapperClassName={classNames(styles.container, styles['is-last'])}
        />
      )}
      <FormFooter
        onSubmit={props.onSubmit}
        onCancel={props.onCancel!}
        isCancelDisabled={props.isLoading}
        submitButtonLabel={props.submitLabel}
        isSubmitDisabled={isLoading || (isValid ? !isDirty : true)}
      />
    </>
  );
};

export default TenantInMarketForm;
