import dayjs from 'dayjs';

import dateFormats from 'constants/dateFormats';
import { isValidDate } from 'utils/date';

export const isValidDateFormat = (
  date: string | undefined | null,
  format: string,
): boolean => {
  if (!date || !isValidDate(date)) return false;

  return dayjs(date, format).format(format) === date;
};

export const isValidISOFormat = (date: string | undefined | null): boolean => {
  return isValidDateFormat(date, dateFormats.ISO_DATE);
};
