import React from 'react';
import classnames from 'classnames';
import { IProperty } from 'interfaces/IProperty';
import { ColorNames } from 'constants/colorNames';
import { ICompSetOrder } from 'contexts/CompSetOrderContext';
import { DragAndDrop } from 'components/DragAndDrop';

import PropertyCardListItem from './PropertyCardListItem';
import styles from './PropertyCardList.module.scss';

interface Props {
  properties: IProperty[];
  cardBackgroundColor?: ColorNames;
  isDisabled?: boolean;
  itemsTopRightButtonClassName?: string;
  noBorder?: boolean;
  wrapperClassName?: string;
  isDraggable?: boolean;
  isPropertyFeatured?: boolean;
  hasFeature?: boolean;
  featuredProperty?: boolean;
  saveContextOrderType?: keyof ICompSetOrder;
  onItemTopRightButtonClick?: (property: IProperty) => void;
  onClickFeatured?: (id: number) => void;
}

interface IPropertyWithId extends IProperty {
  id: number;
}
const PropertyCardList: React.FC<Props> = props => {
  const draggableItems = (
    <DragAndDrop
      items={props.properties as IPropertyWithId[]}
      saveContextOrderType={props.saveContextOrderType}
      renderItem={(property, index) => {
        const propertyId = Number(property.id);
        return (
          <PropertyCardListItem
            key={index}
            onTopRightButtonClick={() =>
              props.onItemTopRightButtonClick?.(property)
            }
            topRightButtonClassName={props.itemsTopRightButtonClassName}
            property={property}
            noBorder={props.noBorder}
            cardBackgroundColor={props.cardBackgroundColor}
            isDisabled={props.isDisabled}
            isPropertyFeatured={props.isPropertyFeatured}
            onTopLeftButtonClick={() => props.onClickFeatured?.(propertyId)}
            hasFeature={props.hasFeature}
          />
        );
      }}
    />
  );

  const propertyCardList = props.properties.map((property, index) => (
    <PropertyCardListItem
      key={index}
      onTopRightButtonClick={() => props.onItemTopRightButtonClick?.(property)}
      topRightButtonClassName={props.itemsTopRightButtonClassName}
      property={property}
      noBorder={props.noBorder}
      cardBackgroundColor={props.cardBackgroundColor}
      isDisabled={props.isDisabled}
    />
  ));
  return props.isDraggable ? (
    draggableItems
  ) : (
    <div className={classnames(styles.container, props.wrapperClassName)}>
      {propertyCardList}
    </div>
  );
};

export default PropertyCardList;
