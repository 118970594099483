import React, { useEffect, useRef, useState } from 'react';
import { select, selectAll } from 'd3-selection';
import isEqual from 'lodash/isEqual';

import { colors } from 'constants/colors';
import { ISegmentLease } from 'interfaces/IExpiringLease';

import {
  getExpiringLeasesBarId,
  getExpiringLeasesBarShadowId,
  getGraphClass,
  GraphClasses,
} from '../nodes';
import { roundedRectPath } from 'components/Graphs/utils/rectPathDrawer';

interface Props {
  data: ISegmentLease[];
  graphId: number;
  primaryColor: string;
  activeColor: string;
  onHoverSegment: (segment?: ISegmentLease) => void;
  onClick: (segment?: ISegmentLease) => void;
  isSmall?: boolean;
}

export type OnHoverParams = {
  segment: ISegmentLease;
  isMouseIn: boolean;
  graphId: number;
  primaryColor: string;
  activeColor: string;
  onHoverSegment?: (segment?: ISegmentLease) => void;
};

const SEGMENT_WIDTH = 2;
const SEGMENT_HEIGHT = 40;
const SEGMENT_HEIGHT_SMALL = 24;
const SEGMENT_RADIUS = 8;
const SHADOW_WIDTH = 10;
const SHADOW_OPACITY = 0.32;
let mouseOutTimeout: any = null;

export const onHover = (params: OnHoverParams) => {
  const {
    segment,
    isMouseIn,
    graphId,
    primaryColor,
    activeColor,
    onHoverSegment,
  } = params;

  const segments = selectAll(`.${getGraphClass(GraphClasses.Bar, graphId)}`);
  const barShadows = selectAll(
    `.${getGraphClass(GraphClasses.BarShadow, graphId)}`,
  );

  segments.style('fill', primaryColor).style('stroke', colors.primaryColor900);

  barShadows.style('display', 'none');
  if (isMouseIn) {
    const d3Segment = select(`#${getExpiringLeasesBarId(segment, graphId)}`);
    const barShadow = select(
      `#${getExpiringLeasesBarShadowId(segment, graphId)}`,
    );

    mouseOutTimeout && clearTimeout(mouseOutTimeout);
    d3Segment.style('fill', activeColor);
    d3Segment.style('stroke', activeColor);
    barShadow.style('display', '');

    onHoverSegment?.(segment);
  } else {
    onHoverSegment?.();
  }
};

// this is required to add a small offset since we are calculating bar width based on its value field
export const getBarXPosition = (d: ISegmentLease, idx: number) => {
  const offset = idx * 2;
  return d.xPosition + offset;
};

const Bar: React.FC<Props> = props => {
  const barRef = useRef(null);
  const {
    data,
    onHoverSegment,
    onClick,
    graphId,
    isSmall,
    activeColor,
    primaryColor,
  } = props;

  const [dataPoints, setDataPoints] = useState<ISegmentLease[]>([]);

  useEffect(() => {
    // Do a deep comparison on the dataPoints, in order to update it only when needed.
    // So the useEffect can redraw the graph only when the data changes.
    if (!isEqual(data, dataPoints)) {
      setDataPoints(data);
    }
  }, [data, dataPoints]);

  const segmentHeight = isSmall ? SEGMENT_HEIGHT_SMALL : SEGMENT_HEIGHT;

  // depending of segment's position/quantity (first, middle, last, or unique) corner radiuses will be different
  const getCornerRadiuses = (idx: number, totalSegments: number) => {
    if (totalSegments > 1) {
      if (idx === 0) {
        return [true, false, true, false];
      }
      if (idx === totalSegments - 1) {
        return [false, true, false, true];
      }
      return [false, false, false, false];
    }
    return [true, true, true, true];
  };

  const drawPath = (d: ISegmentLease, idx: number, arr: any) => {
    return roundedRectPath(
      getBarXPosition(d, idx),
      0,
      d.width,
      segmentHeight,
      SEGMENT_RADIUS,
      ...getCornerRadiuses(idx, arr.length)!,
    );
  };

  const onMouseOverBar = (d: ISegmentLease) => {
    onHover({
      segment: d,
      isMouseIn: true,
      graphId,
      primaryColor,
      activeColor,
      onHoverSegment,
    });
  };

  const onMouseOutBar = (d: ISegmentLease) => {
    mouseOutTimeout && clearTimeout(mouseOutTimeout);
    mouseOutTimeout = setTimeout(() => {
      onHover({
        segment: d,
        isMouseIn: false,
        graphId,
        primaryColor,
        activeColor,
        onHoverSegment,
      });
    }, 100);
  };

  useEffect(() => {
    const node = barRef.current;

    select(node)
      .selectAll(`.${getGraphClass(GraphClasses.Bar, graphId)}`)
      .data(dataPoints)
      .join('path')
      .attr('class', getGraphClass(GraphClasses.Bar, graphId))
      .attr('id', d => getExpiringLeasesBarId(d, graphId))
      .attr('d', drawPath)
      .style('cursor', 'pointer')
      .style('fill', primaryColor)
      .style('stroke-width', SEGMENT_WIDTH)
      .style('stroke', colors.primaryColor900)
      .on('click', onClick)
      .on('mouseover', onMouseOverBar)
      .on('mouseout', onMouseOutBar);

    select(node)
      .selectAll(`.${getGraphClass(GraphClasses.BarShadow, graphId)}`)
      .data(dataPoints)
      .join('path')
      .attr('class', getGraphClass(GraphClasses.BarShadow, graphId))
      .attr('id', d => getExpiringLeasesBarShadowId(d, graphId))
      .attr('d', drawPath)
      .attr('fill', 'transparent')
      .style('cursor', 'pointer')
      .style('stroke-width', SHADOW_WIDTH)
      .style('stroke', activeColor)
      .attr('stroke-opacity', SHADOW_OPACITY)
      .style('display', 'none')
      .on('click', onClick)
      .on('mouseover', onMouseOverBar)
      .on('mouseout', onMouseOutBar);

    // eslint-disable-next-line
  }, [dataPoints, activeColor, primaryColor, graphId]);

  return <g ref={barRef} />;
};

export default Bar;
