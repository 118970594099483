import dayjs from 'dayjs';

import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';
import dateFormats from 'constants/dateFormats';
import { IRadioItem } from 'components/Inputs/RadioInput';
import { TranslateFunction, translateText } from 'utils/i18n';
import { isValidDate } from 'utils/date';

const { MONTH_YEAR, ISO_DATE } = dateFormats;

export const addMonthsToDate = (date: any, monthsToAdd: number): string =>
  dayjs(date)
    .clone()
    .add(monthsToAdd, 'month')
    .format(ISO_DATE);

export const subtractMonthsFromDate = (
  date: string | undefined | null,
  monthsToSubtract: number,
): string =>
  dayjs(date)
    .clone()
    .subtract(monthsToSubtract, 'month')
    .format(ISO_DATE);

export const getMonthsDifference = (
  date1?: string | null,
  date2?: string | null,
): number => {
  const isValid = isValidDate(date1 ?? '') && isValidDate(date2 ?? '');
  const datesDefined = !!date1 && !!date2;
  return !datesDefined || !isValid ? 0 : dayjs(date1!).diff(date2!, 'month');
};

export const getExactMonthDifference = (
  date1?: string | null,
  date2?: string | null,
): number | null => {
  if (!date1 || !date2) return null;

  const start = dayjs(date1);
  const end = dayjs(date2);

  const monthsDiff = end.diff(start, 'month');
  const adjustedStart = start.add(monthsDiff, 'month');

  return adjustedStart.isSame(end, 'day') ? monthsDiff : null;
};

export const getSignDateSuggestions = (date?: string | null): IRadioItem[] => {
  const baseDate = date
    ? dayjs(date, ISO_DATE).utc()
    : dayjs()
        .day(1)
        .utc();

  const currMonthLabel = baseDate.format(MONTH_YEAR);
  const currMonthValue = baseDate.format(ISO_DATE);

  const basePrevDate = baseDate.clone().subtract(1, 'month');
  const prevMonthLabel = basePrevDate.format(MONTH_YEAR);
  const prevMonthValue = basePrevDate.format(ISO_DATE);

  return [
    {
      label: prevMonthLabel,
      value: prevMonthValue,
    },
    {
      label: currMonthLabel,
      value: currMonthValue,
    },
  ];
};

export const getLastDiscussedDateSuggestions = () => [
  {
    label: translateText(
      'avantPlatform.attributes.lease.prompt.setDiscussedDateToday',
    ),
    value: dayjs(undefined, ISO_DATE)
      .utc()
      .format(ISO_DATE),
  },
];

export const getCommencementDateSuggestions = (
  t: TranslateFunction,
): IRadioItem[] => [
  {
    label: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.signedMonths`, {
      n: 3,
    }),
    value: 3,
  },
  {
    label: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.signedMonths`, {
      n: 6,
    }),
    value: 6,
  },
  {
    label: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.signedMonths`, {
      n: 12,
    }),
    value: 12,
  },
];

export const calculateExpirationDate = (
  termInMonths?: number | null,
  commencementDate?: string | null,
): string | undefined => {
  if (!termInMonths || !commencementDate) {
    return undefined;
  }

  if (!isValidDate(commencementDate)) return;

  const expiration = dayjs(commencementDate)
    .add(termInMonths, 'month')
    .subtract(1, 'day');

  return expiration.format(ISO_DATE);
};

export const calculateTermInMonths = (
  expirationDate?: string | null,
  commencementDate?: string | null,
): number | undefined => {
  try {
    if (
      !isValidDate(expirationDate ?? '') ||
      !isValidDate(commencementDate ?? '')
    ) {
      return undefined;
    }

    const end = dayjs(expirationDate!);
    const start = dayjs(commencementDate!);

    const diff = end.diff(start, 'month', true);
    return diff > 0 ? Math.round(diff) : 0;
  } catch (e) {
    return undefined;
  }
};
