import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AgencyAssignmentsProps } from 'components/AgencyAssignments/types';
import TableView from 'components/TableView';
import { generateTableColumns } from 'components/FindComps/PropertiesTableContainer/generateTableColumns';
import { PROPERTIES_TABLE_ID } from 'components/FindComps/PropertiesTableContainer';
import { SortDirections } from 'constants/sortDirections';
import {
  I18N_AVANT_PROPERTY_PAGES,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { SEARCH_PROPERTIES_COMPLETE_QUERY } from 'graphql/property';
import { ISearchOrder } from 'interfaces/ISearch';
import styles from './ListContainer.module.scss';

const ListContainer: React.FC<AgencyAssignmentsProps> = ({ propertySet }) => {
  const { t } = useTranslation();
  const [uncheckedIds, setUncheckedIds] = useState<number[]>([]);

  const [order, setOrder] = useState<ISearchOrder>({
    field: 'primaryAddress',
    direction: SortDirections.asc,
  });
  const propertiesIds =
    propertySet.properties?.map(property => property.id!) || [];

  const getQueryVariables = () => {
    return {
      search: {
        filter: {
          ids: propertiesIds,
        },
      },
    };
  };

  return (
    <div className={classNames(styles['table-wrapper'])}>
      <TableView
        hasEditButton
        columns={generateTableColumns(t)}
        id={PROPERTIES_TABLE_ID}
        noResultsMessage={t(
          `${I18N_AVANT_PROPERTY_PAGES}.findComps.noCompsFound`,
          {
            comp: t(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.property_plural`,
            ).toLowerCase(),
          },
        )}
        onChangeOrder={(field, direction) => {
          setOrder({ field, direction });
          return true;
        }}
        isActive
        order={order}
        scrollClassName={styles['scroll-position']}
        uncheckedIds={uncheckedIds}
        setUncheckedIds={setUncheckedIds}
        query={SEARCH_PROPERTIES_COMPLETE_QUERY}
        getQueryVariables={getQueryVariables}
        typeOfData="properties"
        skip={!propertiesIds?.length}
      />
    </div>
  );
};

export default ListContainer;
