const MARGINS = {
  top: 16,
  right: 24,
  bottom: 32,
  left: 24,
};
const CONTAINER_WIDTH = 640;
const HEIGHT = 46;
const HEIGHT_SMALL = 28;

export const graphDimensions = {
  MARGINS,
  CONTAINER_WIDTH,
  HEIGHT,
  HEIGHT_SMALL,
};

export const DEFAULT_POPUP_SETTINGS = {
  title: '',
  isOpen: false,
  graphqlVariables: undefined,
  action: undefined,
  onClose: undefined,
  graphqlQuery: undefined,
};
