import {
  UNITS_OF_MEASUREMENT_CODE_MAPPING,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import { truncateFloat } from 'utils/formatters/number';
import { IPropertyExpense } from 'interfaces/inputs/IPropertyExpense';
import { convertObjectNumericFields } from 'utils/parsers/convertObjectNumericFields';
import IOffice from 'interfaces/IOffice';
import IIndustry from 'interfaces/IIndustry';
import IDataCenter from 'interfaces/IDataCenter';
import IHealthCare from 'interfaces/IHealthCare';
import { PropertyTypeNames, PropertyTypes } from 'constants/propertyTypes';
import {
  isDataCenterBuilding,
  isIndustrialBuilding,
  isMultifamilyBuilding,
  isOfficeBuilding,
  isLifeScienceBuilding,
} from 'utils/properties';
import { DATA_CENTER_NUMERIC_FIELDS } from 'constants/dataCenter';
import { MULTIFAMILY_NUMERIC_FIELDS } from 'constants/multifamily';
import IMultifamily from 'interfaces/IMultifamily';
import {
  isUKProperty,
  updateBuildingFields,
  updateDataCenterFields,
  updateIndustrialFields,
  updateMultifamilyFields,
  updateOfficeFields,
  updateLandAndDevelopmentFields,
  updateLifeScienceFields,
} from './internationalizationUtils';
import { IdName } from 'interfaces/IdName';
import { ILifeScience } from 'interfaces/inputs/ILifeScience';
import { translateText } from 'utils/i18n';
import { getDateISOString } from 'utils/date';
import { TimeMeasurement } from 'constants/timeMeasurement';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export const updateUnitOfMeasurementFields = (
  property: IPropertyInput,
  updateField: (field: string, value: any) => void,
) => {
  const regionAvailability = {
    isEurope:
      property.measurementSystem === UnitOfMeasurementCode.EU ||
      UNITS_OF_MEASUREMENT_CODE_MAPPING[property.propertyCountry?.code!] ===
        UnitOfMeasurementCode.EU,
    isUK: isUKProperty(property),
  };

  updateBuildingFields(regionAvailability, updateField);
  updateOfficeFields(property, regionAvailability, updateField);
  updateIndustrialFields(property, regionAvailability, updateField);
  updateDataCenterFields(property, regionAvailability, updateField);
  updateMultifamilyFields(property, regionAvailability, updateField);
  updateLandAndDevelopmentFields(regionAvailability, updateField);
  updateLifeScienceFields(property, regionAvailability, updateField);
};

export const getPropertyPayload = (
  propertyInput: IPropertyInput,
  expenseInput: IPropertyExpense,
) => {
  if (propertyInput?.postalCode) {
    propertyInput.postalCode = propertyInput.postalCode.toString();
  }
  const propertyData: IPropertyInput = convertObjectNumericFields(
    propertyInput,
    [
      'buildingSize',
      'buildingSizeMt',
      'currentYearTaxAssessment',
      'floorPlateSize',
      'floorPlateSizeMt',
      'numberOfElevators',
      'convertedFromPropertyId',
      'parkingRatio',
      'parkingSpots',
      'siteSize',
      'siteSizeHectares',
      'stories',
      'siteNumber',
      'siteSizeSf',
      'siteSizeHectares',
      'siteSizeMt',
    ],
  );

  const expense: IPropertyExpense = {
    ...convertObjectNumericFields(expenseInput, [
      'taxAndOpex',
      'operatingExpenses',
      'tax',
      'year',
    ]),
    timeMeasurement: expenseInput.timeMeasurement,
    taxAndOpexChangedManually: expenseInput.taxAndOpexChangedManually,
  };

  let office: IOffice | undefined;
  let lifeScience: ILifeScience | undefined;
  let industrial: IIndustry | undefined;
  let dataCenter: IDataCenter | undefined;
  let healthcare: IHealthCare | undefined;
  let multifamily: IMultifamily | undefined;

  const propertyInputTypeName = propertyInput.propertyType
    ? PropertyTypeNames[propertyInput.propertyType?.id]
    : undefined;
  const isHealthcareType =
    propertyInputTypeName === PropertyTypes.healthcare &&
    !!propertyInput.healthcare;

  if (isOfficeBuilding(propertyInput)) {
    office = convertObjectNumericFields(propertyInput.office, [
      'typicalCeilingHeightMt',
      'typicalCeilingHeightFeet',
      'typicalCeilingHeightInches',
      'columnSpacingMin',
      'columnSpacingMinMt',
      'columnSpacingMax',
      'columnSpacingMaxMt',
      'storiesAboveGround',
      'storiesBelowGround',
    ]);
  }

  if (isLifeScienceBuilding(propertyInput)) {
    lifeScience = convertObjectNumericFields(propertyInput.lifeScience, [
      'loadingDocks',
      'driveInDoors',
      'typicalClearHeightMin',
      'typicalClearHeightMax',
      'slabCeilingHeightFeet',
      'slabCeilingHeightInches',
      'typicalCeilingHeightMt',
      'typicalCeilingHeightFeet',
      'typicalCeilingHeightInches',
      'columnSpacingMin',
      'columnSpacingMinMt',
      'columnSpacingMax',
      'columnSpacingMaxMt',
      'storiesAboveGround',
      'storiesBelowGround',
    ]);
  }
  if (isDataCenterBuilding(propertyInput)) {
    dataCenter = convertObjectNumericFields(
      propertyInput.dataCenter,
      DATA_CENTER_NUMERIC_FIELDS,
    );
  }

  if (isMultifamilyBuilding(propertyInput)) {
    multifamily = convertObjectNumericFields(
      propertyInput.multifamily,
      MULTIFAMILY_NUMERIC_FIELDS,
    );
  }

  if (isIndustrialBuilding(propertyInput)) {
    industrial = convertObjectNumericFields(propertyInput.industrial, [
      'driveInDoors',
      'hookHeight',
      'hookHeightMt',
      'loadingDocks',
      'buildingDepth',
      'trailerParkingSpaces',
      'typicalClearHeightMin',
      'typicalClearHeightMinMt',
      'typicalClearHeightMax',
      'typicalClearHeightMaxMt',
      'columnSpacingMin',
      'columnSpacingMinMt',
      'columnSpacingMax',
      'columnSpacingMaxMt',
    ]);
  }

  if (isHealthcareType) {
    healthcare = convertObjectNumericFields(propertyInput.healthcare, [
      'numBeds',
    ]);
  }

  return {
    ...propertyData,
    comments: [],
    currencyCode: propertyInput.currencyCode,
    expense,
    healthcare,
    industrial,
    office,
    lifeScience,
    dataCenter,
    multifamily,
    buildDate: propertyInput.buildDate,
    brokeGroundDate: propertyInput.brokeGroundDate,
    demolishedConverted: propertyInput.demolishedConverted,
  };
};

export const processPropertyBeforeEdit = (
  property: IPropertyInput,
): IPropertyInput => {
  const { siteSize } = property;

  return {
    ...property,
    brokeGroundDate:
      property.brokeGroundDate && getDateISOString(property.brokeGroundDate),
    buildDate: property.buildDate && getDateISOString(property.buildDate),
    demolishedConverted:
      property.demolishedConverted &&
      getDateISOString(property.demolishedConverted),
    siteSize: siteSize ? truncateFloat(siteSize, 3) : undefined,
  };
};

const getAnnualMonthlyOptions = (): IdName[] => [
  {
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.monthly`),
    id: TimeMeasurement.MONTHLY,
  },
  {
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.annual`),
    id: TimeMeasurement.ANNUAL,
  },
];

const getAnnualMonthlySelectedOptions = (
  selectedOptionId: string,
): IdName | undefined => {
  const options = getAnnualMonthlyOptions();
  return options.find(option => option.id === selectedOptionId);
};

export { getAnnualMonthlyOptions, getAnnualMonthlySelectedOptions };

export const CONSTRUCTION_TYPE_OPTIONS: IdName[] = [
  {
    id: 1,
    name: 'BTS ground-up',
  },

  {
    id: 2,
    name: 'BTS redevelopment',
  },
  {
    id: 3,
    name: 'Spec ground-up',
  },
  {
    id: 4,
    name: 'Spec redevelopment',
  },
];

export const hasPowerCapacityError = (property: IPropertyInput) => {
  const propertyTypeName = PropertyTypeNames[property?.propertyType?.id];
  if (
    ![PropertyTypes.industrial, PropertyTypes.flexIndustrial].includes(
      propertyTypeName,
    )
  )
    return false;

  return (
    !!property?.industrial?.heavyPower &&
    Number(property?.industrial.amps ?? 0) < 3000
  );
};
