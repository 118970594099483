import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { sum } from 'd3';
import { select } from 'd3-selection';
import { scaleLinear, ScaleLinear } from 'd3-scale';
import { DocumentNode } from '@apollo/client';

import { Col, Row } from 'components/@codelitt/ay-design-library';
import CompsPopup from 'components/CompsPopup';

import { colors } from 'constants/colors';
import { ISegmentLease } from 'interfaces/IExpiringLease';
import { ILeaseSearch } from 'interfaces/ILeasesSearch';
import { translateText } from 'utils/i18n';

import GraphContainer from '../GraphContainer';
import Bar from './Elements/Bar';
import { graphDimensions } from './graphConstants';
import Legend from './Elements/Legend';
import { IGraphData } from './types';
import styles from './HorizontalSegmentedBarChart.module.scss';

export interface PopUpSettings {
  title: string;
  isOpen: boolean;
  graphqlVariables: { search: ILeaseSearch } | undefined;
  graphqlQuery: DocumentNode | undefined;
  action: (() => void) | undefined;
  onClose: (() => void) | undefined;
  onChangeData?: () => void;
}

interface Props {
  graphId: number;
  title: string;
  data: IGraphData[];
  unitOfMeasurement: string;
  popUpSettings: PopUpSettings;
  onClick: (segment?: ISegmentLease) => void;
  activeColor?: string;
  primaryColor?: string;
}

export const HorizontalSegmentedBarChart: React.FC<Props> = props => {
  const {
    data = [],
    title,
    unitOfMeasurement,
    activeColor,
    onClick,
    popUpSettings,
    graphId,
    primaryColor,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const [graphContainerDimensions, setGraphContainerDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [activeSegment, setActiveSegment] = useState<ISegmentLease>();

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const { width, height } = containerRef.current!.getBoundingClientRect();
    const { left, right } = graphDimensions.MARGINS;

    if (!graphContainerDimensions.width) {
      setGraphContainerDimensions({ width: width - left - right, height });
    }

    select(svgRef.current)
      .attr('width', width)
      .attr('height', height);
  }, [graphContainerDimensions.width]);

  const totalSum = sum(data, d => d.value)!;

  const MIN_SEGMENT_WIDTH = 10;
  const xScale: ScaleLinear<number, number> = scaleLinear()
    .domain([0, totalSum])
    .range([
      MIN_SEGMENT_WIDTH,
      graphContainerDimensions.width - MIN_SEGMENT_WIDTH * (data.length - 1),
    ]);

  let sumWidths = 0;
  const graphData = data.map(el => {
    const segmentWidth = xScale(el.value);
    const currentXPosition = sumWidths;
    sumWidths += segmentWidth;

    return {
      ...el,
      xPosition: currentXPosition,
      width: segmentWidth,
    } as ISegmentLease;
  });

  const mainColor = primaryColor || colors.primaryColor600;
  const hoverColor = activeColor || colors.primaryColor500;

  return (
    <Row>
      <Col lg={12} md={12} sm={8} xs={4}>
        <GraphContainer
          wrapperClassName={classnames(styles['graph-container'])}
        >
          <div ref={containerRef} className={styles.container}>
            <p className={styles.title}>{title}</p>

            {graphData && (
              <Legend
                data={graphData!}
                idleColor={mainColor}
                activeColor={hoverColor}
                activeSegment={activeSegment}
                unitOfMeasurement={unitOfMeasurement}
              />
            )}
            <svg ref={svgRef} className={styles['svg-graph']}>
              <g
                transform={`translate(${graphDimensions.MARGINS.left}, ${graphDimensions.MARGINS.top})`}
              >
                <Bar
                  data={graphData}
                  onHoverSegment={setActiveSegment}
                  onClick={onClick}
                  graphId={graphId}
                  primaryColor={mainColor}
                  activeColor={hoverColor}
                />
              </g>
            </svg>
          </div>
        </GraphContainer>
      </Col>
      {popUpSettings.isOpen && (
        <CompsPopup
          action={popUpSettings.action}
          actionLabel={translateText(
            `avantProperties.labels.leases.allExpiringLeases`,
          )}
          graphqlVariables={popUpSettings.graphqlVariables}
          onClose={popUpSettings.onClose!}
          title={popUpSettings.title}
          graphqlQuery={popUpSettings.graphqlQuery!}
        />
      )}
    </Row>
  );
};
